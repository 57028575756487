/**
 * Foursource page
 * https://foursource.com/alipay-error/
 */
import { pageTexts } from "../content/alipay-error";
import * as React from "react";
import { withTrans } from "../i18n/withTrans";

// components
import TitleSeparator from "../components/titleSeparator";
import ButtonLink from "../components/_partials/_buttonLink";
import Text from "../components/text";
//svg
import failure from "../images/alert.svg";
class PaymentFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.state = {
      status: new URLSearchParams(props.location.search).get("redirect_status")
    }

  }
  render() {
    let message;
        message = (
          <div className="pt-menu">
            <div className="d-flex justify-content-center text-center">
              <div className="pt-100 pb-100">
                <img src={failure} alt="Failure"></img>
                <div className="pt-30">
                  <TitleSeparator info={this.text.expired}></TitleSeparator>
                  <Text text={this.text.expired.texts}></Text>
                  <div className="row justify-content-center pt-30 pb-30">
                    <ButtonLink
                      text={this.text.backHome.text}
                      url={this.text.backHome.url}
                      type={this.text.backHome.type}
                      extraClass={this.text.backHome.extraClass}
                    ></ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    return (
<div>{message}</div>
    );
  }
}

export default withTrans(PaymentFeedback);
