// icons
import LoadingIcon from "../images/icon/loadingIcon.svg";

export const pageTexts = (t) => {
  return {
    backHome: {
      text: t("alipay-error.backHome"),
      url: "/",
      type: "whiteColor blackBorder",
      extraClass: "bigWidth font14",
    },
    expired: {
      title: {
        text: t("alipay-error.expiredTitle"),
        colorClass: "colorDark",
        extraClass: "font30 m-0 lineHeight1",
      },
      subtitle: {
        text: t("alipay-error.subtitle"),
        colorClass: "colorDarkLight",
        extraClass: "font30 pt-20 lineHeight1 fontWeight400",
      },
      texts: [
        {
          text: t("alipay-error.contactInfo"),
          colorClass: "colorDarkLight",
          extraClass: "font20 lineHeight1_3 pt-15 termsStyle text-center",
          textType: "paragraph",
        },
      ],
    },
    redirect: {
      title: {
        text: t("redirect.redirecting"),
        colorClass: "colorDark",
        extraClass: "font34 m-0",
        heading: "h3",
      },
      text: {
        text: t("redirect.wait"),
        colorClass: "colorDark",
        extraClass: "font26",
        heading: "h4",
      },
      separator: true,
    },
    loading: {
        src: LoadingIcon,
        alt: t("redirect.loadingAlt")
    }
  };
};
